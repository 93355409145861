import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnboardingGuard } from '@app/core/auth/onboarding.guard';
import { EditComponent } from './edit/edit.component';
import { FilesPageComponent } from './files-page/files-page.component';
import { FundsPageComponent } from './funds-page/funds-page.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { PlanDataPageComponent } from './plan-data-page/plan-data-page.component';
import { PlanComponent } from './plan.component';
import { RiskPageComponent } from './risk-page/risk-page.component';
import { SimulationComponent } from './simulation/simulation.component';
import { StatementSummaryPageComponent } from './statement-summary-page/statement-summary-page.component';
import { NavigationItemIdEnum } from '@app/shared/components/navigation/mobile-bottom-navigation/mobile-bottom-navigation.model';

const routes: Routes = [
  {
    path: 'onboarding',
    component: OnboardingComponent,
    canActivate: [OnboardingGuard]
  },
  {
    path: 'simulation',
    component: SimulationComponent
  },
  {
    path: ':id',
    component: PlanComponent,
    data: {
      mobileBottomNavigationItemId:
        NavigationItemIdEnum.STATEMENT
    },
    children: [
      {
        path: 'statement-summary',
        component: StatementSummaryPageComponent
      },
      {
        path: 'funds',
        component: FundsPageComponent
      },
      {
        path: 'files',
        component: FilesPageComponent
      },
      {
        path: 'risk',
        component: RiskPageComponent
      },
      {
        path: 'plan-data',
        component: PlanDataPageComponent
      }
    ]
  },
  { path: ':id/update', component: EditComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlanRoutingModule {}
