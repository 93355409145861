<app-header
  [nameInitials]="nameInitials"
  *ngIf="isHeaderEnabled$ | async as isHeaderEnabled"
></app-header>
<app-ext-header *ngIf="!isHeaderEnabled"></app-ext-header>
<main
  class="app-content-wrapper"
  [ngClass]="{ header_enabled: isHeaderEnabled }"
>
  <router-outlet></router-outlet>
</main>
<app-mobile-bottom-navigation
  *ngIf="isBottomNavigationVisible$ | async"
></app-mobile-bottom-navigation>
<app-footer
  [ngClass]="{
    'is-mobile-bottom-navigation-visible':
      isBottomNavigationVisible$ | async
  }"
></app-footer>
